import { navigate } from "gatsby";
import * as React from "react";
import { useEffect } from "react";

const GithubAuth = () => {
  useEffect(() => {
    // go back to previous page.
    // navigate(-1);
  }, []);

  return (
    <div className="">
      <h1>Github Auth</h1>
      <h3>Callback page</h3>
      <p>
        <a href="/">
          <button type="button">Return to homepage</button>
        </a>
      </p>
    </div>
  );
};

export default GithubAuth;
